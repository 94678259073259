import cx from 'classnames';
import get from 'lodash/get';
import noop from 'lodash/noop';
import some from 'lodash/some';
import values from 'lodash/values';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'semantic-ui-react';

import { EntityTags } from '../../../components/EntityTags';
import ImageIcon from '../../../components/ImageIcon';
import { workshopProptypes } from '../../../propTypes';
import { formatDateRangeDuration } from '../../../utils/dateUtils';
import { getWorkshopString } from '../../utils';
import WorkshopActions from '../WorkshopActions';

export function findOverlappingSession(session, otherSessions) {
  const { startDate, endDate } = session;
  return otherSessions.find(
    (os) => os._id !== session._id && startDate < os.endDate && endDate > os.startDate,
  );
}

function includesAnyOf(source, toFind) {
  if (!toFind?.length) return false;
  return toFind.some((key) => source.includes(key));
}

export function hasOverlappingConstraints(session, otherSessions, sessionsById) {
  if (!session.constraints?.length) return false;

  return otherSessions.some((other) => {
    const otherConstraints = sessionsById[other.sessionId]?.constraints;
    return includesAnyOf(session.constraints, otherConstraints);
  });
}

export function isSessionDisabled(session, { registerOncePerSlot, sessionIds, sessionsById }) {
  if (!registerOncePerSlot) return false;
  const { quota, usersCount, mandatory, workshopId } = session;

  if (mandatory) return false; // Always available....

  const sameSlot = sessionIds[session.startDate];
  if (sameSlot) {
    return sameSlot.workshopId !== workshopId; // Block other slots, keep this one
  }

  const isSessionFull = quota - usersCount <= 0;
  if (isSessionFull) return true;

  const registeredSessions = values(sessionIds);
  if (
    findOverlappingSession(session, registeredSessions) ||
    hasOverlappingConstraints(session, registeredSessions, sessionsById)
  ) {
    return true; // Found overlapping session
  }

  // Find other slot with the same workshopId ?
  if (workshopId && some(registeredSessions, (s) => s.workshopId === workshopId)) {
    return true;
  }

  return false;
}

function renderField(field, session, t, config) {
  const { choiceRequired } = config;
  const { type, key, tagFields = [] } = field;
  const value = get(session, key);
  switch (type) {
    case 'quota': {
      if (!value) return null;
      const { usersCount } = session;
      return `${Math.max(0, value - usersCount) || 0}/${value}`;
    }
    case 'time': {
      return (
        <>
          {t('program.table.time', {
            startDate: session?.startDate,
            endDate: session?.endDate,
          })}
          {choiceRequired && (
            <div className="choice-required">{t('program.table.choice-required')}</div>
          )}
        </>
      );
    }
    case 'duration':
      return formatDateRangeDuration(session.startDate, session.endDate, t);
    case 'tags':
      return <EntityTags tagFields={tagFields} entity={session} type="workshop" />;
    default:
      return getWorkshopString(session, key) || value;
  }
}

export const WorkshopLineActions = (props) => {
  const { session, isDisabled, actions, onSelect, isSelected } = props;
  if (!actions?.length) return null;

  return (
    <Table.Cell className={cx('actions', { disabled: isDisabled })}>
      <WorkshopActions
        actions={actions}
        workshop={session}
        isSelected={isSelected}
        onSelect={onSelect}
      />
    </Table.Cell>
  );
};

WorkshopLineActions.defaultProps = {
  actions: [],
  onSelect: noop,
  isDisabled: false,
  isSelected: false,
};

WorkshopLineActions.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.object),
  onSelect: PropTypes.func,
  isDisabled: PropTypes.bool,
  isSelected: PropTypes.bool,
  session: PropTypes.shape(workshopProptypes).isRequired,
};

function WorkshopLineFields(props) {
  const { session, fields, isDisabled, ...rest } = props;
  const { index, visibleItems, choiceRequired, groupHoursInCell } = rest;
  const { t } = useTranslation();

  return (
    <>
      {fields.map((field) => {
        const { key, type, style, icon } = field;
        if (groupHoursInCell && type === 'time' && index !== 0) return null;
        return (
          <Table.Cell
            rowSpan={(groupHoursInCell && type === 'time' && visibleItems.length) || undefined}
            key={key}
            className={cx(key, {
              disabled: (type !== 'time' || (type === 'time' && !groupHoursInCell)) && isDisabled,
            })}
            style={style}
          >
            {icon && <ImageIcon icon={icon} maxHeight={20} className="icon" />}
            {renderField(field, session, t, { choiceRequired })}
          </Table.Cell>
        );
      })}
    </>
  );
}
WorkshopLineFields.defaultProps = {
  fields: [],
  isDisabled: false,
};

WorkshopLineFields.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.object),
  isDisabled: PropTypes.bool,
  session: PropTypes.shape(workshopProptypes).isRequired,
};

export default WorkshopLineFields;
